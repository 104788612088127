import AlagoasDaSorte from '../../../assets/logos/alagoasdasorte.svg'
import Algar from '../../../assets/logos/algar.svg'
import AppleStore from '../../../assets/logos/appstore.svg'
import Aramis from '../../../assets/logos/aramis.svg'

import Betsul from '../../../assets/logos/betsul.svg'
import BilheteUnico from '../../../assets/logos/bilheteunico.svg'
import Brisanet from '../../../assets/logos/brisanet.svg'

import Centauro from '../../../assets/logos/centauro.svg'
import Claro from '../../../assets/logos/claro.svg'
import ClaroTv from '../../../assets/logos/clarotv.svg'
import Correios from '../../../assets/logos/correios.svg'


import Embratel from '../../../assets/logos/embratel.svg'

import Havaianas from '../../../assets/logos/havaianas.svg'


import Ifood from '../../../assets/logos/ifood.svg'

import ImVu from '../../../assets/logos/imvu.svg'
import Kaspersky from '../../../assets/logos/kaspersky.svg'
import LeagueOfLegends from '../../../assets/logos/leagueoflegends.svg'
import MicrosoftOffice from '../../../assets/logos/microsoftoffice.svg'
import Minecraft from '../../../assets/logos/minecraft.svg'
import Neopets from '../../../assets/logos/neopets.svg'
import Netflix from '../../../assets/logos/netflix.svg'

import OiTv from '../../../assets/logos/oitv.svg'

import PagamentosDeContas from '../../../assets/logos/pagamentosdecontas.svg'

import PernambucoDaSorte from '../../../assets/logos/pernambucodasorte.svg'
import Piraja from '../../../assets/logos/piraja.svg'

import PlayStation from '../../../assets/logos/playstation.svg'

import RioCardMais from '../../../assets/logos/riocardmais.svg'
import Roblox from '../../../assets/logos/roblox.svg'
import SaoPauloDaSorte from '../../../assets/logos/saopaulodasorte.svg'
import SerComTel from '../../../assets/logos/sercomtel.svg'
import Shoulder from '../../../assets/logos/shoulder.svg'
import Sky from '../../../assets/logos/sky.svg'
import Spotify from '../../../assets/logos/spotify.svg'
import Steam from '../../../assets/logos/steam.svg'
import Telefonica from '../../../assets/logos/telefonica.svg'
import TeleSena from '../../../assets/logos/telesena.svg'
import Tim from '../../../assets/logos/tim.svg'
import Tng from '../../../assets/logos/tng.svg'
import TokStok from '../../../assets/logos/tokstok.svg'
import Top from '../../../assets/logos/top.svg'
import Uber from '../../../assets/logos/uber.svg'
import Vivo from '../../../assets/logos/vivo.svg'
import Xbox from '../../../assets/logos/xbox.svg'
import Zinzane from '../../../assets/logos/zinzane.svg'
import EscolaDeDragoes from '../../../assets/logos/schoolofdragons.svg'
import Braz from '../../../assets/logos/braz.svg'
import Camarada from '../../../assets/logos/camarada.svg'
import Cinesystem from '../../../assets/logos/cinesystem.svg'
import Corello from '../../../assets/logos/corello.svg'
import Fogodechao from '../../../assets/logos/fogodechão.svg'
import Gnc from '../../../assets/logos/gnc.svg'
import GooglePlay from '../../../assets/logos/googleplay.svg'
import Guaicurus from '../../../assets/logos/guaicurus.svg'
import Kiddle from '../../../assets/logos/kiddle.svg'
import Tiptop from '../../../assets/logos/tiptop.svg'
import ZeDelivery from '../../../assets/logos/ze.svg'
import MScap from '../../../assets/logos/mscap.svg'
import RazerGold from '../../../assets/logos/razer-gold.svg'
import LevelUp from '../../../assets/logos/levelup.svg'

import { useContext } from 'react'
import { Context } from '../../../Util/Context';
import { Link } from 'react-router-dom';
import styles from './sectionProdutos.module.css'


const chip = [
    { imagem: Tim, nome: 'Tim', negocio: 'TIM' },
    { imagem: Vivo, nome: 'Vivo', negocio: 'VIVO' },
    { imagem: Claro, nome: 'Claro', negocio: 'CLARO' },
    { imagem: Correios, nome: 'Correios', negocio: 'CORREIOS' },
    { imagem: Telefonica, nome: 'Telefonica', negocio: 'TELEFONICA' },
    { imagem: Algar, nome: 'Algar', negocio: 'ALGAR' },
    { imagem: Brisanet, nome: 'Brisanet', negocio: 'BRISANET' },
    { imagem: SerComTel, nome: 'SerComTel', negocio: 'SERCOMTEL' },
    { imagem: Embratel, nome: 'Embratel', negocio: 'EMBRATEL' }
]
const lojaApps = [
    { imagem: GooglePlay, nome: 'GooglePlay Store', negocio: 'RECARGA GOOGLE' },
    { imagem: AppleStore, nome: 'Apple Store', negocio: 'APPLE - APP STORE' }
]
const transporte = [
    { imagem: BilheteUnico, nome: "Bilhete Unico", negocio: 'BILHETE UNICO' },
    { imagem: RioCardMais, nome: "RioCard Mais", negocio: 'RIOCARD MAIS' },
    { imagem: Uber, nome: "Uber", negocio: 'UBER' },
    { imagem: Top, nome: "Top", negocio: 'TOP' },
    { imagem: Guaicurus, nome: 'Consórcio Guaicurus', negocio: 'CARTAO PORTADOR GUAICURUS' }
]
const sorteios = [
    { imagem: Betsul, nome: 'Betsul', negocio: 'BETSUL' },
    { imagem: AlagoasDaSorte, nome: 'Alagoas da Sorte', negocio: 'AL DA SORTE' },
    { imagem: PernambucoDaSorte, nome: 'Pernambuco da Sorte', negocio: 'PE DA SORTE' },
    { imagem: MScap, nome: 'MS Cap', negocio: 'MS CAP' },
    { imagem: SaoPauloDaSorte, nome: 'Sao Paulo da Sorte', negocio: 'SP DA SORTE' },
    { imagem: TeleSena, nome: 'TeleSena', negocio: 'TELE SENA' },

]
const moda = [
    { imagem: Tng, nome: 'Tng', negocio: 'TNG' },
    { imagem: Centauro, nome: 'Centauro', negocio: 'CENTAURO' },
    { imagem: Havaianas, nome: 'Havaianas', negocio: 'HAVAIANAS' },
    { imagem: Zinzane, nome: 'Zinzane', negocio: 'ZINZANE' },
    { imagem: Shoulder, nome: 'Shoulder', negocio: 'SHOULDER' },
    { imagem: Aramis, nome: 'Aramis', negocio: 'ARAMIS' },
    { imagem: Corello, nome: 'Corello', negocio: 'CORELLO' },
    { imagem: Tiptop, nome: 'Tip Top', negocio: 'TIP TOP' }
]

const tvPrePago = [
    { imagem: Sky, nome: 'Sky', negocio: 'SKYTV' },
    { imagem: OiTv, nome: 'Oi Tv', negocio: 'OITV' },
    { imagem: ClaroTv, nome: 'Claro Tv', negocio: 'CLAROTV' },
]
const streamings = [
    { imagem: Netflix, nome: 'Netflix', negocio: 'NETFLIX' },
    { imagem: Spotify, nome: 'Spotify', negocio: 'SPOTIFY' },
]
const restauranteDelevery = [
    { imagem: Ifood, nome: 'Ifood', negocio: 'IFOOD' },
    { imagem: ZeDelivery, nome: 'Zé Delivery', negocio: 'ZE DELIVERY' },
    { imagem: Piraja, nome: 'Piraja', negocio: 'PIRAJA' },
    { imagem: Fogodechao, nome: 'Fogo de chão', negocio: 'FOGO DE CHAO' },
    { imagem: Camarada, nome: 'Camarada camarão', negocio: 'CAMARA CAMARAO' },
    { imagem: Braz, nome: 'Bráz Pizzaria', negocio: 'BRAZ PIZZARIA' },
]
const games = [
    { imagem: PlayStation, nome: "PlayStation", negocio: 'PLAYSTATION' },
    { imagem: Xbox, nome: "Xbox", negocio: 'XBOX MICROSOFT' },
    { imagem: Steam, nome: "Steam", negocio: 'STEAM' },
    { imagem: RazerGold, nome: 'Razer Gold', negocio: 'RAZER GOLD' },
    { imagem: Minecraft, nome: "Minecraft", negocio: 'MINECRAFT' },
    { imagem: Roblox, nome: "Roblox", negocio: 'ROBLOX' },
    { imagem: ImVu, nome: "ImVu", negocio: 'IMVU' },
    { imagem: LeagueOfLegends, nome: "League Of Legends", negocio: 'LEAGUE OF LEGENDS' },
    { imagem: Neopets, nome: "Neopets", negocio: 'NEOPETS' },
    { imagem: EscolaDeDragoes, nome: 'Escola de Dragões', negocio: 'ESCOLA DE DRAGOES' },
    { imagem: LevelUp, nome: 'Level Up', negocio: 'LEVEL UP' },
]
const outros = [
    { imagem: TokStok, nome: 'TokStok', negocio: 'TOK E STOK' },
    { imagem: PagamentosDeContas, nome: 'Pagamentos de contas', negocio: 'PAGAMENTO QR CODE' },
    { imagem: Kaspersky, nome: 'Kaspersky', negocio: 'KASPERSKY' },
    { imagem: MicrosoftOffice, nome: 'Microsoft Office', negocio: 'MICROSOFT OFFICE' },
    { imagem: Cinesystem, nome: 'Cinesystem', negocio: 'CINESYSTEM' },
    { imagem: Gnc, nome: 'GNC', negocio: 'GNC CINEMAS' },
    { imagem: Kiddle, nome: 'Kiddle', negocio: 'KIDDLE PASS' },
]

const all = lojaApps.concat(chip, transporte, moda, sorteios, tvPrePago, outros, streamings, restauranteDelevery, games)

export default function ListaDeProdutos(props) {
    const { setDadosProdutos } = useContext(Context);

    function handleClick(nome, negocio, img) {
        setDadosProdutos({ nome: nome, negocio: negocio, img: img })
    }

    function ListProd(produtos) {
        const ListProdHtml = produtos.map((prod, pos) =>

            <Link component={Link} to={'/Mapa'} home={false} >
                <div className={styles.sectionProdutosRecargaItem} onClick={() => handleClick(prod.nome, prod.negocio, prod.imagem)} key={pos} data-toggle="tooltip" data-placement="top" title={prod.nome}>
                    <img src={prod.imagem} alt=""></img>
                </div>
            </Link>
        )
        return ListProdHtml
    }


    switch (props.value) {
        case 'all':
            return (
                <>
                    {ListProd(all)}
                </>
            )
        case 'chip':
            return (
                <>
                    {ListProd(chip)}
                </>
            )
        case 'apps':
            return (
                <>
                    {ListProd(lojaApps)}
                </>
            )
        case 'transporte':
            return (
                <>
                    {ListProd(transporte)}
                </>
            )
        case 'sorteios':
            return (
                <>
                    {ListProd(sorteios)}
                </>
            )
        case 'moda':
            return (
                <>
                    {ListProd(moda)}
                </>
            )
        case 'tv':
            return (
                <>
                    {ListProd(tvPrePago)}
                </>
            )
        case 'streamings':
            return (
                <>
                    {ListProd(streamings)}
                </>
            )
        case 'restaurante':
            return (
                <>
                    {ListProd(restauranteDelevery)}
                </>
            )
        case 'games':
            return (
                <>
                    {ListProd(games)}
                </>
            )
        case 'mais':
            return (
                <>
                    {ListProd(outros)}
                </>
            )
        default:
            return (
                <>
                    {ListProd(all)}
                </>
            )
    }
}