import BannerPromocao from '../../object/banner/promocao/BannerPromocao'
import BannerProdutos from '../../object/banner/produtos/BannerProdutos'

import './promo.css'


export default function Promo(){
    return(
      <>
        <BannerPromocao/>
        <BannerProdutos/>
      </>
    )
}