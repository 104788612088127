import styles from './sectionCard.module.css'
import CardLogoMin from '../../../assets/logos/card-logo-min.svg'

export default function SectionCard(){
    return (
        <>
            <section className={styles.sectionCard}>
                    <img className={styles.sectionCardItems} src={CardLogoMin} alt="Logo Grupo Card"/>
                    <h4 className={styles.sectionCardItems} >Já conhece a <span>card</span>?</h4>
                    <p className={styles.sectionCardItems}>Visite o nosso site e descubra as soluções para você e seu negócio</p>
                    <a className={styles.sectionCardButton} href="https://www.grupocard.com.br/" target="_blank" rel="noreferrer">Acesse já</a>
            </section>
        </>
    )
}