import { createContext, useState, useRef } from "react";

const ModalProdutosContext = createContext({})
const Context = createContext();

function AutoComplete({ children }) {
    const [autocomplete, setAutocomplete] = useState(null);
    const [dadosEstabelecimentos, setDadosEstabelecimentos] = useState(null)
    const [lojas, setLojas] = useState([])
    const [location, setLocation] = useState({ lat: null, lng: null });
    const [atuaizarTela, setAtuaizarTela] = useState(false)
    const [pesquisaAtiva, setPesquisaAtiva] = useState(false)
    //Avenida 3, 225 - Vila Nova Campo Garande, Campo Grande - MS, Brasil
    const [dadosProdutos, setDadosProdutos] = useState(null)
    const onPlaceChanged = (home) => {
        setLojas([])
        setPesquisaAtiva(true)
        const place = autocomplete?.getPlace();
        const lat = place?.geometry?.location.lat();
        const lng = place?.geometry?.location.lng();

        let coordenadas = { lat: lat, lng: lng }
        setCirculo({
            strokeColor: "#FF6600",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillOpacity: 0.2,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            radius: km * 1000,
            center: coordenadas,
        })
        setLocation({ lat: lat, lng: lng });
        if (home) {
            setAtuaizarTela(true)
        }

    };

    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };
    const [modal, setModal] = useState(false);
    const originRef = useRef()
    const [circulo, setCirculo] = useState({})
    const [km, setKm] = useState(0.5)
    const toggle = () => setModal(!modal);
    const minhaLocalizacao = async (home) => {

        const navegador = window.navigator;

        const geo = navegador.geolocation

        if (pesquisaAtiva === false) {
            if (geo) {
                navigator.geolocation.getCurrentPosition(async function (position) {
                    const { latitude, longitude } = position.coords;
                    // console.log('pegou a latitude e longitude', latitude, longitude)
                    let coordenadas = { lat: latitude, lng: longitude }
                    setCirculo({
                        strokeColor: "#FF6600",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#D3D3D3",
                        fillOpacity: 0.2,
                        clickable: false,
                        draggable: false,
                        editable: false,
                        visible: true,
                        radius: km * 1000,
                        zIndex: 1,
                        center: coordenadas,
                    })



                    let localizacao = { lat: latitude, lng: longitude }
                    setLocation(localizacao);
                    if (home) {
                        setAtuaizarTela(true)
                    }
                }, (err) => { console.log(err) });
            } else {
                setModal(!modal)
                alert("Geolocalização não suportada pelo seu navegador");
            }
        }


    }

    return (
        <Context.Provider
            value={{
                setAutocomplete: setAutocomplete,
                onLoad: (autocomplete) => onLoad(autocomplete),
                autocomplete: autocomplete,
                lojas: lojas,
                setLojas: setLojas,
                location: location,
                setLocation: setLocation,
                onPlaceChanged: (home) => onPlaceChanged(home),
                modal: modal,
                setModal: setModal,
                originRef: originRef,
                circulo: circulo,
                km: km,
                setKm: setKm,
                minhaLocalizacao: (home) => minhaLocalizacao(home),
                toggle: () => toggle(),
                setCirculo: setCirculo,
                atuaizarTela: atuaizarTela,
                pesquisaAtiva: pesquisaAtiva,
                setPesquisaAtiva: setPesquisaAtiva,
                dadosProdutos: dadosProdutos,
                setDadosProdutos: setDadosProdutos,
                setDadosEstabelecimentos: setDadosEstabelecimentos,
                dadosEstabelecimentos: dadosEstabelecimentos
            }}
        >
            {children}
        </Context.Provider>
    );
}
export { ModalProdutosContext, Context, AutoComplete };


