import Inicio from '../Views/Home/index';
import Mapa from '../Views/Mapa/index'
import { Routes, Route } from 'react-router-dom';

const Rotas = () => {

    return (
        <>
            <Routes>
                <Route exact path="/" element={<Inicio />} />
            </Routes>

            <Routes>
                <Route exact path="/Mapa" element={<Mapa />} />
            </Routes>

        </>
    );
};

export default Rotas;