import React, { useContext, useEffect } from 'react'
import { Autocomplete, } from '@react-google-maps/api'
import Logo from '../../../assets/logos/LogoIncontreFull.svg'
import Lupa from '../../../assets/icons/search-button.svg'
import Localizacao from '../../../assets/icons/localizacao-button.svg'
import { useNavigate } from 'react-router-dom';
import { Input } from '@chakra-ui/react';
import styles from './autocomplete.module.css'


import { Context } from '../../../Util/Context';
function AutoComplete({ home }) {
    let navigate = useNavigate();
    const { originRef, onPlaceChanged, onLoad, minhaLocalizacao, atuaizarTela } = useContext(Context);
    useEffect(() => {
        if (atuaizarTela) {
            navigate('/Mapa');
        }
    }, [atuaizarTela,navigate])
    return (
        <>
            <header className={styles.headerContainer} >
                <div className={styles.headerContainerItemLogo} >
                    <a href='/' >
                        <img className={styles.headerLogo} src={Logo} alt='Incontre Pontos de recarga card'/>
                    </a>
                </div>
                <div className={styles.headerContainerItemBusca}>
                        <Autocomplete onLoad={onLoad} onPlaceChanged={() => onPlaceChanged(home)} >
                            <Input type='text' placeholder='Digite o endereço' ref={originRef} />
                        </Autocomplete>
                        <button className={styles.headerContainerItemBuscaButton} type='submit'>
                            <img src={Lupa} alt='Botao para confirmar busca'/>
                        </button> 
                        <button className={styles.headerContainerItemLocalizacaoButton} onClick={() => minhaLocalizacao(home)} >
                            <img src={Localizacao} alt='Botao para utilizar a localizacao atual'/>
                        </button>
                </div>
            </header>
        </>
        //                 <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
        //                     Minha localização
        //                 </UncontrolledTooltip>
  
    )
}
export default AutoComplete