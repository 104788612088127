import { SkeletonText } from '@chakra-ui/react'
import Promo from '../../components/promo/Promo'
import Section from '../../components/section/Section'
import Footer from '../../components/footer/Footer';
import AutoComplete from '../../Views/Mapa/autoComplete/index';
import { useJsApiLoader } from '@react-google-maps/api'
import '../../global.css'
const libraries = ["places", "geometry"];
export default function App() {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: libraries,
    })

    if (!isLoaded) {
        return <SkeletonText />
    }

    return (
        <div className="App">
            <AutoComplete home={true}></AutoComplete>
            <Promo />
            <Section />
            <Footer />
        </div>
    );
}


