// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bannerprodutos_sliderProdutosContainer__nUU1j {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .bannerprodutos_sliderProdutosBannerText__iPlWV {
    font-family: var(--goldplay-regular);
    color:var(--gray-11);
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    margin-top: 28px;
  }
  
  .bannerprodutos_sliderProdutos__mk5kD {
    margin: 26px;
    width: 736px;
  }
  
  .bannerprodutos_sliderProdutosItem__zWlmp {
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    background: var(--gray-2);
    width: 40px;
    height: 68px;
  }
  
  .bannerprodutos_sliderProdutosItem__zWlmp img {
    display: grid;
    place-items: center;
    padding: 5px 5px 5px 10px;
  }
  
  .bannerprodutos_slick-list__C1ucN {
    margin: 0 -5px;
  }
  .bannerprodutos_slick-slide__ZpMyK > div {
    padding: 0 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/object/banner/produtos/bannerprodutos.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,oCAAoC;IACpC,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,yBAAyB;EAC3B;;EAEA;IACE,cAAc;EAChB;EACA;IACE,cAAc;EAChB","sourcesContent":[".sliderProdutosContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .sliderProdutosBannerText {\n    font-family: var(--goldplay-regular);\n    color:var(--gray-11);\n    font-size: 20px;\n    font-weight: 400;\n    text-align: center;\n    margin-top: 28px;\n  }\n  \n  .sliderProdutos {\n    margin: 26px;\n    width: 736px;\n  }\n  \n  .sliderProdutosItem {\n    border-radius: 15px;\n    text-align: center;\n    cursor: pointer;\n    background: var(--gray-2);\n    width: 40px;\n    height: 68px;\n  }\n  \n  .sliderProdutosItem img {\n    display: grid;\n    place-items: center;\n    padding: 5px 5px 5px 10px;\n  }\n  \n  .slick-list {\n    margin: 0 -5px;\n  }\n  .slick-slide > div {\n    padding: 0 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderProdutosContainer": `bannerprodutos_sliderProdutosContainer__nUU1j`,
	"sliderProdutosBannerText": `bannerprodutos_sliderProdutosBannerText__iPlWV`,
	"sliderProdutos": `bannerprodutos_sliderProdutos__mk5kD`,
	"sliderProdutosItem": `bannerprodutos_sliderProdutosItem__zWlmp`,
	"slick-list": `bannerprodutos_slick-list__C1ucN`,
	"slick-slide": `bannerprodutos_slick-slide__ZpMyK`
};
export default ___CSS_LOADER_EXPORT___;
