import ImgMapa from '../../../assets/icons/mapa-incontre.png'
import ImgBarraPesquisa from '../../../assets/icons/barra-de-pesquisa-incontre.png'
import styles from './sectionComoFunciona.module.css';
export default function SectionComoFunciona() {
    return (
        <>
            <section className={styles.howtoContainer}>
                <div className={styles.howtoItems}>
                    <div>
                    <h2 className={styles.howtoTextTitle}>Como o <span>InContre</span> funciona?</h2>
                    </div>
                    <div className={styles.howtoSearchbar}>
                        <picture >
                            <img className={styles.imgBarraPesquisa} src={ImgBarraPesquisa} alt='Imagem contendo as instruções de como acessar o Incontre, na barra de pesquisa do inicio da página existe a possibilidade de 
                            digitar o endereço do local que deseja encontrar a recarga para o produto que necessita ou clicando na mesma barra de pesquisa o ícone de localização
                            para encontrar o ponto mais próximo de você' /> 
                        </picture>
                        <div>
                            <p className={styles.howtoTextCenter}>No mapa você encontrará filtros para facilitar sua busca</p>
                        </div>
                    </div>  
                </div>
                    <div>
                         <img className={styles.howtoImageMap} src={ImgMapa} alt="Imagem do mapa Incontre mostrando suas formas de filtrar os produtos e mostrando suas lojas de recarga mais próximas" />
                    </div>
            </section>
        </>
    )
}