// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper {
    min-width: 60%;
    max-width: 70%;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 35px;
}

.swiper-slide img {
    max-width: 43.31rem;
    max-height: 24.31rem;
    object-fit: cover;
}

.swiper-pagination-bullet-active{
    background-color: #333333;
}

.swiper-pagination-bullets,
.swiper-pagination-bullet-active{
    margin-top: 20px;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: white;
    background-color: rgba(255, 255, 255, 0.5);
    right:10px;
    padding: 30px;
    color: #000 !important;
    fill: black !important;
    stroke: black !important;
    
}
`, "",{"version":3,"sources":["webpack://./src/object/banner/promocao/bannerpromocao.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;;IAEI,gBAAgB;AACpB;;AAEA;;IAEI,uBAAuB;IACvB,0CAA0C;IAC1C,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,wBAAwB;;AAE5B","sourcesContent":[".swiper {\n    min-width: 60%;\n    max-width: 70%;\n}\n\n.swiper-slide {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-bottom: 35px;\n}\n\n.swiper-slide img {\n    max-width: 43.31rem;\n    max-height: 24.31rem;\n    object-fit: cover;\n}\n\n.swiper-pagination-bullet-active{\n    background-color: #333333;\n}\n\n.swiper-pagination-bullets,\n.swiper-pagination-bullet-active{\n    margin-top: 20px;\n}\n\n.swiper-button-next,\n.swiper-button-prev {\n    background-color: white;\n    background-color: rgba(255, 255, 255, 0.5);\n    right:10px;\n    padding: 30px;\n    color: #000 !important;\n    fill: black !important;\n    stroke: black !important;\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
