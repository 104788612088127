// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-produtos-container{
  display: flex;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  background: var(--white);
  width: 810px;
  box-shadow: 0px 0px 3.9px rgba(0, 0, 0, 0.15);
}

.slider-produtos {
  padding: 5px;
  width: 736px;
}

.slider-produtos-item {
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  background: #f2f2f2;
  width: 40px;
  height: 68px;
  box-shadow: 0px 0px 3.9px rgba(0, 0, 0, 0.15);
}

.slider-produtos-item img {
  margin: 7px auto;
}

.slick-list {
  margin: 0 -5px;
}
.slick-slide > div {
  padding: 0 5px;
}
`, "",{"version":3,"sources":["webpack://./src/object/banner/produtos/bannerProdutoMapa.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,wBAAwB;EACxB,YAAY;EACZ,6CAA6C;AAC/C;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,6CAA6C;AAC/C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB","sourcesContent":[".slider-produtos-container{\n  display: flex;\n  align-items: center;\n  border-radius: 20px;\n  justify-content: center;\n  background: var(--white);\n  width: 810px;\n  box-shadow: 0px 0px 3.9px rgba(0, 0, 0, 0.15);\n}\n\n.slider-produtos {\n  padding: 5px;\n  width: 736px;\n}\n\n.slider-produtos-item {\n  border-radius: 15px;\n  text-align: center;\n  cursor: pointer;\n  background: #f2f2f2;\n  width: 40px;\n  height: 68px;\n  box-shadow: 0px 0px 3.9px rgba(0, 0, 0, 0.15);\n}\n\n.slider-produtos-item img {\n  margin: 7px auto;\n}\n\n.slick-list {\n  margin: 0 -5px;\n}\n.slick-slide > div {\n  padding: 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
