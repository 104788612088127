import './global.css'
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from '../src/Rota/rota';
import { AutoComplete } from './Util/Context';
export default function App() {
  return (
    <AutoComplete>
      <Router>
        <Routes />
      </Router>
    </AutoComplete>
  );
}


