import { useEffect, useState } from 'react'
import ListaDeProdutos from './ListaDeProdutos'
import styles from './sectionProdutos.module.css'


function Link({children,isActive,onClick}){
    return(
        <>
            <a className={isActive ?  styles['linkActive'] : styles['link']} onClick={onClick} href>{children}</a>
        </>
    )
}


export default function SectionProdutos() {
    const [tipoProduto, setTipoProduto] = useState({})

    useEffect(() => {
        setTipoProduto('all')
    },[])

    const handleClick = (value) => {
        return setTipoProduto(value)
    }

    return (
        <>
            <section className={styles.sectionProdutosContainer}>
                <h1>São dezenas de produtos</h1>
                <div className={styles.sectionProdutosContainer}>
                    <div className={styles.sectionProdutosFilterItemsPrimeiraLinha}>
                        <div className={styles.sectionProdutosFilterItem}>
                            <Link isActive={tipoProduto === 'all'} onClick={() => handleClick('all')}>Todos os Produtos</Link>
                        </div>
                        <div className={styles.sectionProdutosFilterItem}>
                            <Link isActive={tipoProduto === 'chip'} onClick={() => handleClick('chip')}>TELECOM</Link>
                        </div>
                        <div className={styles.sectionProdutosFilterItem}>
                            <Link isActive={tipoProduto === 'apps'} onClick={() => handleClick('apps')}>Loja de Apps</Link>
                        </div>
                        <div className={styles.sectionProdutosFilterItem}>
                            <Link isActive={tipoProduto === 'transporte'} onClick={() => handleClick('transporte'   )}>Mobilidade Urbana</Link>
                        </div>
                        <div className={styles.sectionProdutosFilterItem}>
                            <Link isActive={tipoProduto === 'sorteios'} onClick={() => handleClick('sorteios')}>Sorteios e Apostas</Link>
                        </div>
                    </div>
                    <div className={styles.sectionProdutosFilterItemsSegundaLinha}>
                        <div className={styles.sectionProdutosFilterItem}>
                                <Link isActive={tipoProduto === 'moda'} onClick={() => handleClick('moda')}>Moda</Link>
                            </div>
                            <div className={styles.sectionProdutosFilterItem}>
                                <Link isActive={tipoProduto === 'tv'} onClick={() => handleClick('tv')}>TV Pré-Pago</Link>
                            </div>
                            <div className={styles.sectionProdutosFilterItem}>
                                <Link isActive={tipoProduto === 'streamings'} onClick={() => handleClick('streamings')}>Streamings</Link>
                            </div>
                            <div className={styles.sectionProdutosFilterItem}>
                                <Link isActive={tipoProduto === 'restaurante'} onClick={() => handleClick('restaurante')}>Restaurantes e Delivery</Link>
                            </div>
                            <div className={styles.sectionProdutosFilterItem}>
                                <Link isActive={tipoProduto === 'games'} onClick={() => handleClick('games')}>Games</Link>
                            </div>
                            <div className={styles.sectionProdutosFilterItem}>
                                <Link isActive={tipoProduto === 'mais'} onClick={() => handleClick('mais')}>E muito Mais</Link>
                            </div>
                    </div>
                    <div className={styles.sectionProdutosRecargaItems}>
                        <ListaDeProdutos value={tipoProduto}></ListaDeProdutos>
                    </div>
                </div>
            </section>
        </>
    )
}