// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.logo{
    margin-top: 5px;
    width:6rem;
    height:1.81rem;
}
.header-logo{
    display: flex;
    align-items: center;
    margin:10px 50px;
}

form{
    width: 60%;
    height: 70%;
    border-radius: 5px;
    background-color: #F2F2F2;
    margin: 12px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

form > input{
    border: none;
    border-radius: 20px;
    background-color: #F2F2F2;
    padding-top: 10px;
    height: 70%;
}

::placeholder{
    text-align: center;
    padding-bottom: 40px;
}

.localization-button{
    border-radius: 0 20px 20px 0;
    border-left: var(--primary) solid 2px ;
}

.localization-button, .search-button {
    background: #F2F2F2;
    border: none;
    color: #fff;
    padding: 4px 10px;
    cursor: pointer;
}

span{
    color: var(--secundary);
}

p{
    position: relative;
    color: #6C6E6F;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.bar-div{
    color: #343434;
} */`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAmEG","sourcesContent":["/* .header-container{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n}\r\n.logo{\r\n    margin-top: 5px;\r\n    width:6rem;\r\n    height:1.81rem;\r\n}\r\n.header-logo{\r\n    display: flex;\r\n    align-items: center;\r\n    margin:10px 50px;\r\n}\r\n\r\nform{\r\n    width: 60%;\r\n    height: 70%;\r\n    border-radius: 5px;\r\n    background-color: #F2F2F2;\r\n    margin: 12px 50px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\nform > input{\r\n    border: none;\r\n    border-radius: 20px;\r\n    background-color: #F2F2F2;\r\n    padding-top: 10px;\r\n    height: 70%;\r\n}\r\n\r\n::placeholder{\r\n    text-align: center;\r\n    padding-bottom: 40px;\r\n}\r\n\r\n.localization-button{\r\n    border-radius: 0 20px 20px 0;\r\n    border-left: var(--primary) solid 2px ;\r\n}\r\n\r\n.localization-button, .search-button {\r\n    background: #F2F2F2;\r\n    border: none;\r\n    color: #fff;\r\n    padding: 4px 10px;\r\n    cursor: pointer;\r\n}\r\n\r\nspan{\r\n    color: var(--secundary);\r\n}\r\n\r\np{\r\n    position: relative;\r\n    color: #6C6E6F;\r\n    font-family: 'Nunito Sans', sans-serif;\r\n    font-size: 16px;\r\n    font-weight: 400;\r\n}\r\n\r\n.bar-div{\r\n    color: #343434;\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
