import Padarias from '../../../assets/icons/sm/padaria.svg'
import Farmacias from '../../../assets/filtro/Farmacia.svg'
import Conveniencias from '../../../assets/icons/sm/conveniencia.svg'
import Lanchonetes from '../../../assets/icons/sm/lanchonete.svg'
import Bancas from '../../../assets/icons/sm/banca.svg'
import Mercados from '../../../assets/icons/sm/mercado.svg'
import Bares from '../../../assets/icons/sm/bares.svg'
import AssistenciaTecnica from '../../../assets/icons/sm/assistenciatecnica.svg'
import Emuitomais from '../../../assets/icons/sm/emuitomais.svg'
import Papelarias from '../../../assets/icons/sm/Papelarias.svg'
import { Context } from '../../../Util/Context';
import { Link } from 'react-router-dom';
import styles from './sectionEstabelecimento.module.css'
import { useConst } from '@chakra-ui/react'
import { useContext } from 'react'

export default function SectionEstabelecimento() {
    const { setDadosEstabelecimentos } = useContext(Context);
    const estabelecimento = useConst(
        [
            { imagem: Padarias, nome: 'Padarias', segmento: 'Padarias' },
            { imagem: Farmacias, nome: 'Farmácias', segmento:'Farmácias'},
            { imagem: Conveniencias, nome: 'Conveniências', segmento: 'Conveniências', },
            { imagem: Lanchonetes, nome: 'Lanchonetes', segmento: 'Lanchonetes', },
            { imagem: Bancas, nome: 'Bancas', segmento: 'Chaveiros' },
            { imagem: Mercados, nome: 'Mercados', segmento: ['Armazéns', 'Mercearias', 'Hortifruti'] },
            { imagem: Bares, nome:'Bares', segmento:'Bares'},
            { imagem: AssistenciaTecnica, nome: 'Assistência técnica', segmento:['Assistência Técnica','Lojas Assistência Técnica']},
            { imagem: Papelarias, nome: 'Papelarias', segmento: 'Papelarias'},
            { imagem: Emuitomais, nome:'e muito mais', segmento: 'All'}

        ])

    
    function handleClick(nome, segmento, img) {
        setDadosEstabelecimentos({ nome: nome, segmento: segmento, img: img })
    }

    return (
        <section className={styles.sectionContainer}>
            <div className={styles.sectionWrapper}>
            <div className={styles.sectionTextContainer}>
                <div className={styles.sectionText}>
                    <h3>Encontre recargas de seus produtos em diversos estabelecimentos</h3>
                </div>
            </div>
            <div className={styles.sectionItemContainer}>
                {
                    estabelecimento.map((lista) =>
                        <div onClick={() => handleClick(lista.nome, lista.segmento, lista.imagem)}>
                            <Link className={styles.sectionItem} component={Link} to={'/Mapa'} home={false}>
                                <img src={lista.imagem} alt={`Opção de estabelecimento ${lista.nome}`} />
                                <p className={styles.textEstabelecimento}>{lista.nome}</p>
                            </Link>
                        </div>
                    )
                }
            </div>
            </div>
        </section>
    )
}