import Marcador from '../../../assets/icons/marcador/Marcador.svg'
import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react'
import './style.css'
import { Col, Row } from 'reactstrap';
export default function FiltroLateral({ filtroLateral, setFiltroLateral, Expandir, comoChegar, voltar, Contrair }) {
    const abrir = (loja) => {
        let dados2 = JSON.parse(JSON.stringify(filtroLateral));
        dados2.map((lista) => {
            if (lista.id === loja.id && lista.aberto === false) {
                lista.aberto = true
            } else {
                lista.aberto = false
            }
        }
        )
        setFiltroLateral(dados2)


    }
    const fechar = (loja) => {
        let dados2 = JSON.parse(JSON.stringify(filtroLateral));
        dados2.map((lista) => {
            lista.aberto = false
        }
        )
        setFiltroLateral(dados2)


    }



    return (
        <>


            <div className="card" data-toggle="tooltip" data-placement="top" title="Loja dentro do raio selecionado">

                <div className="card-body box positionFixedBottom">


                    {
                        filtroLateral?.map((lista, chave) =>
                            <Box style={{ display: 'flex' }} >
                                <Box className='marcadorLateral'>

                                    <img src={Marcador} alt='Marcador' style={{ width: '40px' }} ></img>
                                    <span className='fonteDistancia'>
                                        {lista.distancia >= 1 ? lista.distancia.toFixed(2) + ' Km' : (lista.distancia * 1000).toFixed(0) + 'm'}
                                    </span>
                                </Box>
                                <Box style={{ maxWidth: '14vw' }}>
                                    <Box>
                                        <span className='tituloLateral'>{lista.NOME_ESTABELECIMENTO?.toUpperCase()}</span>
                                    </Box>
                                    <div className='textoEndereco'>
                                        {lista.SEGMENTO}
                                    </div>
                                    <Box>
                                        <span className='tituloRecargaDisponivel'>Recargas Diponiveis:</span>
                                    </Box>

                                    <Box className='tituloProduto'>

                                        {
                                            !lista.aberto ?
                                                lista.listaNegocio?.map((negocio, index) =>
                                                    <Box style={{ display: 'flex' }}>
                                                        <Box >
                                                            {index < 2 ? negocio + ' , ' : index === 3 && negocio + '.'}
                                                        </Box>
                                                        <Box>
                                                            {index === 3 &&
                                                                <img src={Expandir}
                                                                    onClick={() => abrir(lista)}
                                                                    alt='Buscar mais' style={{ width: '15px', marginLeft: '5px', cursor: 'pointer' }}
                                                                    data-toggle="tooltip" data-placement="top" title="Mostar todos os produtos"
                                                                ></img>}
                                                        </Box>
                                                    </Box>

                                                ) :
                                                <Row>
                                                    <Col sm={10} md={10} xs={10} id='detalhesLojas'>
                                                        {
                                                            lista.listaNegocio.slice(0, -1).join(', ')
                                                            + (lista.listaNegocio.length > 1 ? ' e ' : '') +
                                                            lista.listaNegocio[lista.listaNegocio.length - 1] + '.'}

                                                    </Col>
                                                    <Col style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                                                        <img src={Contrair}
                                                            onClick={() => fechar(lista)}
                                                            alt='Buscar mais' style={{ width: '15px', marginLeft: '5px', cursor: 'pointer' }}
                                                            data-toggle="tooltip" data-placement="top" title="Minimizar"
                                                        ></img>
                                                    </Col>

                                                </Row>


                                        }



                                    </Box>

                                    <div className='textoEndereco'>
                                        {lista.ENDERECO + ', ' + lista.NUMERO + ', ' + lista.BAIRRO + ', ' + lista.CIDADE + ' - ' + lista.ESTADO}
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        {
                                            lista.selecionado ? <button className='btn' id='voltar' onClick={() => voltar(lista)}>  Voltar</button> :
                                                <button className='btn' id='comoChegar' onClick={() => comoChegar(lista.location.lat, lista.location.lon, lista)}>
                                                    Como chegar
                                                </button>
                                        }

                                    </div>

                                    <hr style={{ marginTop: '20px' }}></hr>
                                </Box>


                            </Box>

                        )
                    }
                </div>

            </div>
        </>

    )
}