// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sectionCard_sectionCard__bzYcP{
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
}

.sectionCard_sectionCard__bzYcP h4{
    font-family: var(--goldplay-regular);
    color: var(--gray-11);
    font-size: 20px;
}

.sectionCard_sectionCard__bzYcP span{
    font-family: var(--goldplay-semibold-alt);
    color:var(--secundary);
}

.sectionCard_sectionCard__bzYcP p{
    font-family: var(--goldplay-regular);
    color: var(--gray-11);
    font-size: 20px;
    width: 18%;
    text-align: center;
}

.sectionCard_sectionCardButton__PkAuC{
    font-size: 20px;
    border-radius: 20px;
    width: 6.5%;
    text-align: center;
    background: var(--secundary);
    color:var(--white);
    padding:5px;
    margin:10px;
}

.sectionCard_sectionCardButton__PkAuC:hover{
    color:var(--white);
    text-decoration: none;
}

@media only screen and (min-width:1030px) and (max-width:1280px){
    .sectionCard_sectionCardButton__PkAuC{
        width: 8.5%;
    }
}

@media only screen and (min-width:1366px){
    .sectionCard_sectionCardButton__PkAuC{
        width: 8.5%;
    }
}`, "",{"version":3,"sources":["webpack://./src/object/Section/card/sectionCard.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,oCAAoC;IACpC,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,yCAAyC;IACzC,sBAAsB;AAC1B;;AAEA;IACI,oCAAoC;IACpC,qBAAqB;IACrB,eAAe;IACf,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,4BAA4B;IAC5B,kBAAkB;IAClB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".sectionCard{\n    width: 100%;\n    height: 350px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    row-gap: 10px;\n}\n\n.sectionCard h4{\n    font-family: var(--goldplay-regular);\n    color: var(--gray-11);\n    font-size: 20px;\n}\n\n.sectionCard span{\n    font-family: var(--goldplay-semibold-alt);\n    color:var(--secundary);\n}\n\n.sectionCard p{\n    font-family: var(--goldplay-regular);\n    color: var(--gray-11);\n    font-size: 20px;\n    width: 18%;\n    text-align: center;\n}\n\n.sectionCardButton{\n    font-size: 20px;\n    border-radius: 20px;\n    width: 6.5%;\n    text-align: center;\n    background: var(--secundary);\n    color:var(--white);\n    padding:5px;\n    margin:10px;\n}\n\n.sectionCardButton:hover{\n    color:var(--white);\n    text-decoration: none;\n}\n\n@media only screen and (min-width:1030px) and (max-width:1280px){\n    .sectionCardButton{\n        width: 8.5%;\n    }\n}\n\n@media only screen and (min-width:1366px){\n    .sectionCardButton{\n        width: 8.5%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionCard": `sectionCard_sectionCard__bzYcP`,
	"sectionCardButton": `sectionCard_sectionCardButton__PkAuC`
};
export default ___CSS_LOADER_EXPORT___;
