import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './style.css'
function Example({ modal, toggle }) {


    return (
        <div>

            <Modal isOpen={modal} toggle={toggle}>

                <ModalBody>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div className="c-loader"></div>

                    </div>
                </ModalBody>

            </Modal>
        </div>
    );
}

export default Example;