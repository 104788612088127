// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bannerpromocao_bannerPromocaoContainer__qQZo6 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.bannerpromocao_bannerPromocaoTitle__OE3st{
    padding: 30px;
    text-align: center;
    font-size: 30px;
    font-family:var(--goldplay-regular);
}

.bannerpromocao_bannerPromocaoTitle__OE3st > span{
    font-family: var(--goldplay-semibold-alt);
    font-weight: 600;
    color:var(--secundary);
}`, "",{"version":3,"sources":["webpack://./src/object/banner/promocao/bannerpromocao.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,mCAAmC;AACvC;;AAEA;IACI,yCAAyC;IACzC,gBAAgB;IAChB,sBAAsB;AAC1B","sourcesContent":[".bannerPromocaoContainer {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n}\n\n.bannerPromocaoTitle{\n    padding: 30px;\n    text-align: center;\n    font-size: 30px;\n    font-family:var(--goldplay-regular);\n}\n\n.bannerPromocaoTitle > span{\n    font-family: var(--goldplay-semibold-alt);\n    font-weight: 600;\n    color:var(--secundary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerPromocaoContainer": `bannerpromocao_bannerPromocaoContainer__qQZo6`,
	"bannerPromocaoTitle": `bannerpromocao_bannerPromocaoTitle__OE3st`
};
export default ___CSS_LOADER_EXPORT___;
