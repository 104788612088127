import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Ze from "../../../assets/imagens/zeDeliveri.png";
import "./bannerpromocao.css";
import styles from "./bannerpromocao.module.css";
import { useState, useContext } from "react";
import { Context } from "../../../Util/Context";
import { Link } from "react-router-dom";

import ZeDeliveryIcon from "../../../assets/logos/ze.svg";

export default function BannerPromocao() {
  const { setDadosProdutos } = useContext(Context);

  function handleClick(nome, negocio, img) {
    setDadosProdutos({ nome: nome, negocio: negocio, img: img });
  }

  return (
    <>
      <article className={styles.bannerPromocaoContainer}>
        <h1 className={styles.bannerPromocaoTitle}>
          Encontre pontos de venda <span>card</span> e recarregue <br />
          seus produtos e serviços favoritos
        </h1>

        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="sliderPromocao"
        >
          <SwiperSlide>
            <div
              onClick={() =>
                handleClick("Ze Delivery", "ZE DELIVERY", ZeDeliveryIcon)
              }
            >
              <Link component={Link} to={"/Mapa"} home={false}>
                <img src={Ze} alt="Banner do produto Zé Delivery" />
              </Link>
            </div>
          </SwiperSlide>
        </Swiper>
      </article>
    </>
  );
}
