import './section.css'

import SectionEstabelecimento from '../../object/Section/estabelecimento/SectionEstabelecimento'
import SectionComoFunciona from '../../object/Section/comofunciona/SectionComoFunciona'
import SectionProdutos from '../../object/Section/produtos/SectionProdutos'
import SectionCard from '../../object/Section/card/SectionCard'


export default function Section(){
    return(
        <>
            <SectionEstabelecimento/>
            <SectionComoFunciona/>
            <SectionProdutos/>
            <SectionCard/>
        </>
    )
}