import { Box, Flex, SkeletonText, } from '@chakra-ui/react'
import { useJsApiLoader, GoogleMap, Marker, Circle, DirectionsRenderer, InfoWindow } from '@react-google-maps/api'
import { Col, Row, Container } from 'reactstrap';
import Expandir from '../../assets/filtro/Expandir.svg'
import Contrair from '../../assets/filtro/Contrair.svg'
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react'
import '../../components/header/header.css';
import api from '../../Util/api'
import './style.css'
import { FaArrowsAltH } from 'react-icons/fa'
import Marcador from '../../assets/icons/marcador/Marcador.svg'
import PinLocal from '../../assets/icons/marcador/pinLocal.svg'
import Esqueda from '../../assets/raio/Esquerda.svg'
import Direita from '../../assets/raio/Direita.svg'
import FiltroLateral from './filtroLateral';
import Modal from './modalLoad/index'
import { Context } from '../../Util/Context';
import AutoComplete from './autoComplete';
import Fechar from '../../assets/imagens/fechar.png'
import BannerProdutos from '../../object/banner/produtos/BannerProdutosMapa'
import Swal from 'sweetalert2';

const blueMarker = {
    url: Marcador,
    fillColor: '#FF6600',
    fillOpacity: 1,
    strokeWeight: 0,
    scale: 10,
};
const redMarker = {
    url: PinLocal,
    fillColor: '#FF6600',
    fillOpacity: 1,
    strokeWeight: 0,
    scale: 10,
};


const ModalEstabelecimentos = lazy(() => import('../../object/Modal/ModalEstabelecimentos'));
const ModalProdutos = lazy(() => import('../../object/Modal/ModalProdutos'));



const libraries = ["places", "geometry"];
function Mapa({ home }) {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: libraries,
    })

    const { dadosProdutos, setDadosProdutos,
        lojas, setLojas, location,
        minhaLocalizacao, modal,
        setModal, circulo, km, setKm, toggle,
        setCirculo, atuaizarTela, pesquisaAtiva,
        setPesquisaAtiva, dadosEstabelecimentos,
        setDadosEstabelecimentos } = useContext(Context);
    const [filtroLateral, setFiltroLateral] = useState([])
    const [zoom, setZoom] = useState(16)
    const [map, setMap] = useState(/** @type google.maps.Map */(null))
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const [lojaUnica, setLojaUnica] = useState(null)
    const [pontosDeVenda, setPontosDeVenda] = useState(null)
    const [localizacaoAux, setLocalizacaoAux] = useState(location)





    function filtroProdutos(dados, { negocio }) {
        const chave = new RegExp(`${negocio}*`, 'g')
        let filtroProduto = dados?.filter((lista) => chave.test(JSON.stringify(lista.PRODUTOS)))
        return filtroProduto
    }

    function filtroEstabelecimento(dados, dadosEstabelecimentos) {
        let filtroEstabelecimento = dados.filter((dado) => dadosEstabelecimentos.segmento.includes(dado.SEGMENTO))
        return filtroEstabelecimento
    }

    function filtrarPontosDeVenda(response) {

        if (dadosEstabelecimentos === null && dadosProdutos === null) {

            return response

        } else if (dadosEstabelecimentos !== null && dadosProdutos !== null) {
            const responseProdutos = filtroProdutos(response, dadosProdutos)
            const responseEstabelecimento = filtroEstabelecimento(response, dadosEstabelecimentos)
            const responseProdutosEstabelecimentos = responseProdutos.concat(responseEstabelecimento)
            return responseProdutosEstabelecimentos
        } else if (dadosEstabelecimentos?.segmento !== 'ALL' && dadosProdutos?.negocio === 'ALL') {
            return filtroEstabelecimento(response, dadosEstabelecimentos)
        } else if (dadosProdutos?.negocio !== 'ALL' && dadosEstabelecimentos?.segmento === 'ALL') {
            return filtroProdutos(response, dadosProdutos)
        } else if (dadosEstabelecimentos?.segmento !== 'ALL' && dadosProdutos === null) {

            return filtroEstabelecimento(response, dadosEstabelecimentos)
        } else if (dadosProdutos?.negocio !== 'ALL' && dadosEstabelecimentos === null) {

            return filtroProdutos(response, dadosProdutos)
        }



    }





    const buscarPontosDeVendas = async () => {

        setModal(true)

        if (location.lat !== null && location?.lng !== null) {
            // `/estabelecimentos?latitude=${location?.lat}&longitude=${location?.lng}&distancia=${km * 1000}`
            await api
                .get(`/estabelecimentos?latitude=${location?.lat}&longitude=${location?.lng}&distancia=${km * 1000}`, {
                    headers: {
                        'x-tnd-time': '01677502175668',
                        'x-grupo-economico': '87',
                    },
                },)
                .then(async (response) => {

                    if ((dadosEstabelecimentos !== null || dadosProdutos !== null) && (dadosProdutos.nome !== 'Produtos' || dadosEstabelecimentos.nome !== 'Estabelecimento')) {
                        let pontos = filtrarPontosDeVenda(response.data)
                        setLojas(pontos)
                        setFiltroLateral(pontos)
                        setModal(false)
                    } else {
                        setFiltroLateral(response.data)
                        setLojas(response.data)
                        setModal(false)
                    }
                }).catch((error) => {
                    setModal(false)

                })
        }
        setModal(false)

    }





    useEffect(() => {

        if ((localizacaoAux.lat !== location.lat || localizacaoAux.lng !== location.lng)) {

            setLocalizacaoAux(location)
            minhaLocalizacao(false)
            buscarPontosDeVendas()
            setPesquisaAtiva(false)
        }
    }, [location, atuaizarTela])



    useEffect(() => {
        if (dadosEstabelecimentos !== null || dadosProdutos !== null) {
            buscarPontosDeVendas()
        }
    }, [dadosEstabelecimentos, dadosProdutos])





    useEffect(() => {
        if (performance.getEntriesByType && performance.getEntriesByType("navigation").length) {
            var navigationEntry = performance.getEntriesByType("navigation")[0];
            if (navigationEntry.type === "reload") {
                setPontosDeVenda(null)
            }
        } else {
            window.addEventListener('beforeunload', function (event) {
                if (event.currentTarget.performance.navigation.type === PerformanceNavigation.TYPE_RELOAD) {
                    setPontosDeVenda(null)
                }
            });
        }


        if (atuaizarTela === false && pesquisaAtiva === false) {
            minhaLocalizacao(false)
        }

        buscarPontosDeVendas()
    }, [])






    const subtraiKm = () => {

        let valor = km >= 0.5 ? km - 0.5 : 0.5;
        valor = parseFloat(valor.toFixed(2));
        setKm(valor)
        setTimeout(() => {
            setMostrar(true)
        }, "2000");
        setMostrar(false)
        setZoom(zoom + 0.5)

    }
    const [mostrar, setMostrar] = useState(false)
    const somaKm = () => {
        let valor = km <= 3 ? km + 0.5 : 3;
        valor = parseFloat(valor.toFixed(2));

        setKm(valor)
        setTimeout(() => {
            setMostrar(true)
        }, "2000");
        setMostrar(false)
        setZoom(zoom - 0.5)
    }

    useEffect(() => {
        if (mostrar) {
            atualizarRaio()
        }
    }, [mostrar])
    const atualizarRaio = () => {
        let circuloAux = JSON.parse(JSON.stringify(circulo));
        circuloAux.radius = km * 1000
        circuloAux.center = { lat: localizacaoAux?.lat, lng: localizacaoAux?.lng }
        setCirculo(circuloAux)
        buscarPontosDeVendas()
    }


    const [aguardarCarregamento, setAguardarCarregamento] = useState(false)
    const comoChegar = async (latitude, longitude, loja) => {
        setModal(true)

        const directionsService = new window.google.maps.DirectionsService()
        const results = await directionsService.route({
            origin: new window.google.maps.LatLng(location?.lat, location?.lng),
            destination: new window.google.maps.LatLng(latitude, longitude),
            // eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING,
        })
        let dados2 = JSON.parse(JSON.stringify(filtroLateral));
        dados2.map((lista) => {
            if (lista.id === loja.id && lista.selecionado === false) {
                lista.selecionado = true
            } else {
                lista.selecionado = false
            }
        }
        )
        setModal(false)
        setFiltroLateral(dados2)
        setLojaUnica(loja)
        setDirectionsResponse(results)
    }

    const voltar = (loja) => {

        setAguardarCarregamento(true)
        setDirectionsResponse(null)
        let dados2 = JSON.parse(JSON.stringify(filtroLateral));
        dados2.map((lista) => {
            if (lista.id === loja.id) {
                lista.selecionado = false
            }
        }
        )

        setFiltroLateral(dados2)
        setLojaUnica(null)
        setAguardarCarregamento(false)
    }

    const [activeMarker, setActiveMarker] = useState(false);
    const [localizacaoUnica, setLocalizacaoUnica] = useState()
    const [informacaoLoc, setInformacaoLoc] = useState([])
    const handleMouseOver = (lista) => {

        setInformacaoLoc(lista)
        setLocalizacaoUnica({ lat: parseFloat(lista.LATITUDE), lng: parseFloat(lista.LONGITUDE) })
        setActiveMarker(true);
    };

    const handleMouseOut = (e) => {
        setActiveMarker(false);
    };
    const abrir = (loja) => {
        let dados2 = JSON.parse(JSON.stringify(informacaoLoc));

        if (dados2.id === loja.id && dados2.aberto === false) {
            dados2.aberto = true
        } else {
            dados2.aberto = false
        }


        setInformacaoLoc(dados2)


    }


    const fechar = () => {
        let dados2 = JSON.parse(JSON.stringify(informacaoLoc));
        dados2.aberto = false
        setInformacaoLoc(dados2)
    }


    if (!isLoaded) {
        return <SkeletonText />
    }


    return (
        <div>
            <Modal modal={modal} toggle={toggle}></Modal>

            <AutoComplete home={false}></AutoComplete>
            <Flex
                position='relative'
                flexDirection='column'
                alignItems='center'
                h='92vh'
                w='100vw'
            >

                <Box position='absolute' zIndex={0} h='100%' w='100%'>


                    {
                        directionsResponse ? <GoogleMap
                            center={localizacaoAux}

                            mapContainerStyle={{ width: '100%', height: '100%', }}
                            options={{
                                //zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                                disableDefaultUI: true,
                                zoomControl: true,
                            }}
                            onLoad={map => setMap(map)}
                        >
                            {

                                <>
                                    <Marker
                                        position={directionsResponse.request.destination.location}
                                        icon={blueMarker}
                                    />
                                    <DirectionsRenderer
                                        directions={directionsResponse}
                                        options={{ suppressMarkers: true }}
                                    />

                                    <Marker
                                        position={directionsResponse.request.origin.location}
                                        icon={redMarker}
                                    />
                                </>

                            }


                        </GoogleMap> : <GoogleMap
                            center={localizacaoAux}
                            zoom={zoom}
                            mapContainerStyle={{ width: '100%', height: '100%', }}
                            options={{
                                //zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                                disableDefaultUI: true,
                                zoomControl: true,
                            }}
                            onLoad={map => setMap(map)}
                            onClick={handleMouseOut}
                        >
                            <Marker position={localizacaoAux} icon={redMarker} />
                            <Circle options={circulo} />
                            {
                                lojas.length > 0 &&
                                <>
                                    {lojas.map((lista, index) =>
                                        <>
                                            <Marker key={index} position={{ lat: lista.location.lat, lng: lista.location.lon }} icon={blueMarker}
                                                onClick={() => handleMouseOver(lista)}
                                            // onMouseOut={(e) => handleMouseOut(e)}
                                            >
                                                {
                                                    activeMarker &&
                                                    lista.id === informacaoLoc.id &&
                                                    <InfoWindow position={localizacaoUnica} data-toggle="tooltip" data-placement="top" title="Loja selecionada" className='modalLoja'>
                                                        {

                                                            <Box style={{ display: 'flex', cursor: 'pointer' }} >
                                                                <Box className='marcadorLateral'>

                                                                    <img src={Marcador} alt='Marcador' style={{ width: '40px' }} ></img>
                                                                    <span className='fonteDistancia'>
                                                                        {informacaoLoc.distancia >= 1 ? informacaoLoc.distancia.toFixed(2) + ' Km' : (informacaoLoc.distancia * 1000).toFixed(0) + 'm'}
                                                                    </span>
                                                                </Box>
                                                                <Box style={{ maxWidth: '14vw' }}>
                                                                    <Box>
                                                                        <span className='tituloLateral'>{informacaoLoc.NOME_ESTABELECIMENTO?.toUpperCase()}</span>
                                                                    </Box>
                                                                    <div className='textoEndereco'>
                                                                        {informacaoLoc.SEGMENTO}
                                                                    </div>
                                                                    <Box>
                                                                        <span className='tituloRecargaDisponivel'>Recargas Diponiveis:</span>
                                                                    </Box>

                                                                    <Box className='tituloProduto'>

                                                                        {
                                                                            !informacaoLoc.aberto ?
                                                                                informacaoLoc.listaNegocio?.map((negocio, index) =>
                                                                                    <Box style={{ display: 'flex' }}>
                                                                                        <Box >
                                                                                            {index < 2 ? negocio + ' , ' : index === 3 && negocio + '.'}
                                                                                        </Box>
                                                                                        <Box>
                                                                                            {index === 3 &&
                                                                                                <img src={Expandir}
                                                                                                    onClick={() => abrir(lista)}
                                                                                                    alt='Buscar mais' style={{ width: '15px', marginLeft: '5px', cursor: 'pointer' }}
                                                                                                    data-toggle="tooltip" data-placement="top" title="Mostar todos os produtos"
                                                                                                ></img>}
                                                                                        </Box>
                                                                                    </Box>

                                                                                ) :
                                                                                <Row>
                                                                                    <Col sm={10} md={10} xs={10} id='detalhesLojas'>
                                                                                        <div >
                                                                                            {
                                                                                                informacaoLoc.listaNegocio.slice(0, -1).join(', ')
                                                                                                + (informacaoLoc.listaNegocio.length > 1 ? ' e ' : '') +
                                                                                                informacaoLoc.listaNegocio[informacaoLoc.listaNegocio.length - 1] + '.'}
                                                                                        </div>


                                                                                    </Col>
                                                                                    <Col style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                                                                                        <img src={Contrair}
                                                                                            onClick={() => fechar(lista)}
                                                                                            alt='Buscar mais' style={{ width: '15px', marginLeft: '5px', cursor: 'pointer' }}
                                                                                            data-toggle="tooltip" data-placement="top" title="Minimizar"
                                                                                        ></img>
                                                                                    </Col>

                                                                                </Row>


                                                                        }



                                                                    </Box>

                                                                    <div className='textoEndereco'>
                                                                        {informacaoLoc.ENDERECO + ', ' + informacaoLoc.NUMERO + ', ' + informacaoLoc.BAIRRO + ', ' + informacaoLoc.CIDADE + ' - ' + informacaoLoc.ESTADO}
                                                                    </div>
                                                                    <div style={{ marginTop: '10px' }}>
                                                                        {
                                                                            informacaoLoc.selecionado ? <button className='btn' id='voltar' onClick={() => voltar(informacaoLoc)}>  Voltar</button> :
                                                                                <button className='btn' id='comoChegar' onClick={() => comoChegar(informacaoLoc.location.lat, informacaoLoc.location.lon, informacaoLoc)}>
                                                                                    Como chegar
                                                                                </button>
                                                                        }

                                                                    </div>

                                                                    <hr style={{ marginTop: '20px' }}></hr>
                                                                </Box>


                                                            </Box>


                                                        }
                                                    </InfoWindow>
                                                }

                                            </Marker>

                                        </>


                                    )}
                                </>
                            }
                        </GoogleMap>
                    }
                </Box>
                <Container className='container-nav-lateral-direito'>
                    <div className="container-nav-lateral-direito-items" >
                        <Row >
                            <Col className='container-nav-lateral-modal-raio'>

                                <div data-toggle="tooltip" data-placement="top" title="Raio selecionado">
                                    <Row className="modalRaio">
                                        <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                            <div className='textoRaio'>  <p>Raio</p></div>
                                            <div style={{ padding: '5px' }}><FaArrowsAltH></FaArrowsAltH></div>
                                            <div className='textoRaio'> <p>{km} km</p></div>
                                        </Col>

                                    </Row>
                                    <Row className="modalRaio" style={{ position: 'relative', display: 'flex', borderRadius: '0 0 10px 10px', }}>
                                        <Col >
                                            {km < 0.5 ? '' :

                                                <img src={Esqueda} alt="icone" onClick={() => subtraiKm()} />
                                            }
                                        </Col>
                                        <Col >
                                            <span className='textoKm'>{km}km</span>
                                        </Col>
                                        <Col >
                                            {
                                                km < 3 &&

                                                <img src={Direita} alt="icone" onClick={() => somaKm()} />
                                            }

                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    {
                                        lojaUnica != null ?
                                            <div onClick={() => voltar(lojaUnica)} data-toggle="tooltip" data-placement="top" title="Loja selecionada">
                                                <Row className='modalLoja'>
                                                    <Col sm={3} md={3} xs={3} className='marcadorLateralModalLoja'>
                                                        <img src={Marcador} alt='Marcador' style={{ width: '25px' }} ></img>
                                                        <span className='fonteDistancia'>
                                                            {lojaUnica.distancia >= 1 ? lojaUnica.distancia.toFixed(2) + ' Km' : (lojaUnica.distancia * 1000).toFixed(0) + 'm'}
                                                        </span>

                                                    </Col>

                                                    <Col sm={8} md={8} xs={8} style={{ padding: '10px' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <span className='tituloLateral'>{lojaUnica.NOME_ESTABELECIMENTO?.toUpperCase()}</span>

                                                        </div>

                                                        <div className='textoEndereco'>
                                                            {lojaUnica.ENDERECO + ', ' + lojaUnica.NUMERO + ', ' + lojaUnica.BAIRRO + ', ' + lojaUnica.CIDADE + ' - ' + lojaUnica.ESTADO}
                                                        </div>

                                                    </Col>
                                                    <Col sm={1} md={1} xs={1} >
                                                        <button>
                                                            <img src={Fechar} alt='Fechar' style={{ width: '10px' }} data-toggle="tooltip" data-placement="top" title="Fechar"></img>
                                                        </button>

                                                    </Col>
                                                </Row>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Suspense fallback="<h1>Carregando...</h1>">
                        <ModalProdutos dadosProdutos={dadosProdutos} setDadosProdutos={setDadosProdutos} />
                        <ModalEstabelecimentos

                            dadosEstabelecimentos={dadosEstabelecimentos}
                            setDadosEstabelecimentos={setDadosEstabelecimentos} />
                    </Suspense>
                </Container>
                <div className="filtro-slider">
                    <Suspense fallback="">
                        <BannerProdutos />
                    </Suspense>
                </div>
            </Flex >

            <Container >
                <Row>
                    <Col sm={12} md={12} xs={12} style={{ display: 'flex' }}>
                        <FiltroLateral
                            filtroLateral={filtroLateral}
                            setFiltroLateral={setFiltroLateral}
                            Expandir={Expandir}
                            comoChegar={comoChegar}
                            voltar={voltar}
                            aguardarCarregamento={aguardarCarregamento}
                            Contrair={Contrair}
                        ></FiltroLateral>
                    </Col>

                </Row>
            </Container>

        </div>
    )
}

export default Mapa
