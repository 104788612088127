import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './bannerProdutoMapa.css'

import PrevBtn from '../../../assets/icons/prev-btn.svg'
import NextBtn from '../../../assets/icons/next-btn.svg'

import PlayStation from '../../../assets/logos/playstation.svg'
import Xbox from '../../../assets/logos/xbox.svg'
import Netflix from '../../../assets/logos/netflix.svg'
import BilheteUnico from '../../../assets/logos/bilheteunico.svg'
import PlayStore from '../../../assets/logos/playstore.svg'
import Spotify from '../../../assets/logos/spotify.svg'
import Steam from '../../../assets/logos/steam.svg'
import Ifood from '../../../assets/logos/ifood.svg'
import Uber from '../../../assets/logos/uber.svg'
import PagamentosDeContas from '../../../assets/logos/pagamentosdecontas.svg'
import RioCard from '../../../assets/logos/riocardmais.svg'
import AppStore from '../../../assets/logos/appstore.svg'
import Tim from '../../../assets/logos/tim.svg'
import Claro from '../../../assets/logos/claro.svg'
import Vivo from '../../../assets/logos/vivo.svg'
import TeleSena from '../../../assets/logos/telesena.svg'
import ZeDelivery from '../../../assets/logos/ze.svg'
import Sky from '../../../assets/logos/sky.svg'
import Roblox from '../../../assets/logos/roblox2.svg'
import { useState, useContext } from 'react'
import { Context } from '../../../Util/Context';
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img
            className={className}
            style={{ ...style, width: "13px", height: "24px" }}
            onClick={onClick}
            src={NextBtn}
            alt="Botao proximo"
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img
            className={className}
            style={{ ...style, width: "13px", height: "24px" }}
            onClick={onClick}
            src={PrevBtn}
            alt="Botao anterior"
        />
    );
}

export default function BannerProdutos() {

    const settings = {
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />

    }
    const [produto, setProduto] = useState(
        [
            { imagem: PlayStation, produto: "PLAYSTATION", nome: "PlayStation" },
            { imagem: Xbox, produto: "XBOX", nome: "Xbox" },
            { imagem: Netflix, produto: "NETFLIX", nome: "Netflix" },
            { imagem: BilheteUnico, produto: "BILHETE UNICO", nome: "BilheteUnico" },
            { imagem: RioCard, produto: "RIOCARD", nome: "RioCard" },
            { imagem: PlayStore, produto: "RECARGA GOOGLE", nome: "PlayStore" },
            { imagem: Spotify, produto: "SPOTIFY", nome: "Spotify" },
            { imagem: Steam, produto: "STEAM", nome: "Steam" },
            { imagem: Uber, produto: "UBER", nome: "Uber" },
            { imagem: Ifood, produto: "IFOOD", nome: "Ifood" },
            { imagem: ZeDelivery, produto: 'ZE DELIVERY', nome: 'Zé Delivery'},
            { imagem: AppStore, produto: "APPLE - APP STORE", nome: "AppStore" },
            { imagem: PagamentosDeContas, produto: "PAGAMENTO QR CODE", nome: "PagamentosDeContas" },
            { imagem: Sky, produto: "SKY", nome: "Sky" },
            { imagem: Tim, produto: "TIM", nome: "Tim" },
            { imagem: Claro, produto: "CLARO", nome: "Claro" },
            { imagem: Vivo, produto: "VIVO", nome: "Vivo" },
            { imagem: Roblox, produto: "ROBLOX", nome: "Roblox" },
            { imagem: TeleSena, produto: 'TELE SENA', nome: 'Tele Sena'}
        ]
    )
    const { setDadosProdutos } = useContext(Context);


    function handleClick(nome, negocio, img) {
        setDadosProdutos({ nome: nome, negocio: negocio, img: img })
    }


    return (
        <div className="slider-produtos-container">

            <Slider className="slider-produtos"{...settings}>
                {
                    produto.map((lista) =>
                        <div className="slider-produtos-item" onClick={() => handleClick(lista.nome, lista.produto, lista.imagem)}>
                            <img src={lista.imagem} alt={`produto ${lista.nome}`} />
                        </div>
                    )
                }
            </Slider>
        </div>
    )
}