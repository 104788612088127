// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  background-color: transparent !important;
  border: transparent !important;
}

.c-loader {
  animation: is-rotating 1s infinite;
  border: 6px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: #ff6600;
  height: 100px;
  width: 100px;
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}
.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/Views/Mapa/modalLoad/style.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,8BAA8B;AAChC;;AAEA;EACE,kCAAkC;EAClC,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,YAAY;AACd;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF;AACA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,sBAAsB;AACxB","sourcesContent":[".modal-content {\n  background-color: transparent !important;\n  border: transparent !important;\n}\n\n.c-loader {\n  animation: is-rotating 1s infinite;\n  border: 6px solid #e5e5e5;\n  border-radius: 50%;\n  border-top-color: #ff6600;\n  height: 100px;\n  width: 100px;\n}\n\n@keyframes is-rotating {\n  to {\n    transform: rotate(1turn);\n  }\n}\n.modal {\n  text-align: center;\n  padding: 0 !important;\n}\n\n.modal:before {\n  content: \"\";\n  display: inline-block;\n  height: 100%;\n  vertical-align: middle;\n  margin-right: -4px;\n}\n\n.modal-dialog {\n  display: inline-block;\n  text-align: left;\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
