import Card from '../../assets/icons/card-logo.svg'
import Facebook from '../../assets/icons/facebook.svg'
import Instagram from '../../assets/icons/instagram.svg'
import Linkedin from '../../assets/icons/linkedin.svg'
import Telefone from '../../assets/icons/telefone.svg'
import Whatsapp from '../../assets/icons/whatsapp.svg'

import styles from './footer.module.css'

export default function Footer(){
    return (
        <>
            <footer className={styles.footerContainer}>
                <div className={styles.footerItems}>
                    <img src={Card} alt="Logo Card"/>
                    <div className={styles.divisionFooter}/>
                    <div className={styles.footerItemTelefone}>
                            <p className={styles.footerItemTelefoneText}>Fale Conosco</p>
                            <div className={styles.footerItemTelefoneContato}>
                                <a href="tel:+5501130033304" rel="noreferrer" target="_blank"><img src={Telefone} alt="Telefone Card"/></a>
                                <a href="https://api.whatsapp.com/send?phone=551130033304&text=Olá" rel="noreferrer" target="_blank"><img src={Whatsapp} alt="Whatsapp Card"/></a>
                                <p className={styles.footerItemTelefoneContatoText}>11 3003 3304</p>
                            </div>
                            <h5 className={styles.footerItemTextDays}>Segunda a sexta das 7h às 19h40*  </h5>
                            <h5 className={styles.footerItemTextDays}>Sábado das 8h às 14h20*</h5>
                            <h5 className={styles.footerItemTextDays}>*Horário de Brasília</h5>
                    </div>
                    <div className={styles.divisionFooter}/>
                    <div className={styles.footerItemSociais}>
                        <p className={styles.footerItemSociaisText}>Siga a <span>card</span> nas redes sociais</p>
                        <a  className={styles.footerItemSociais} href="https://br.linkedin.com/company/grupocardoficial" rel="noreferrer" target="_blank"><img src={Linkedin} alt="Atalho para acesso ao Linkedin Grupo Card"/></a>
                        <a  className={styles.footerItemSociais} href="https://www.facebook.com/grupocardoficial" rel="noreferrer" target="_blank"><img src={Facebook} alt="Atalho para acesso ao Facebook Grupo Card" /></a>
                        <a  className={styles.footerItemSociais} href="https://www.instagram.com/grupocard/" rel="noreferrer" target="_blank"><img src={Instagram} alt="Atalho para acesso ao Instagram Grupo Card" /></a>
                    </div>
                </div>
            </footer>
        </>
    )
}
